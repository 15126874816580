import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm server no longer works (URL Encoding)",
  "path": "/Frequently_Asked_Question/Alarmserver_no_longer_works/",
  "dateChanged": "2019-12-24",
  "author": "Mike Polinowski",
  "excerpt": "The alarm has already worked once after I got the camera about 2 weeks ago and then immediately installed the latest firmware.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Alarm server no longer works (URL Encoding)' dateChanged='2019-12-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='The alarm has already worked once after I got the camera about 2 weeks ago and then immediately installed the latest firmware.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Alarmserver_no_longer_works/' locationFR='/fr/Frequently_Asked_Question/Alarmserver_no_longer_works/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "alarm-server-no-longer-works-url-encoding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alarm-server-no-longer-works-url-encoding",
        "aria-label": "alarm server no longer works url encoding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm server no longer works (URL Encoding)`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: The alarm has already worked once after I got the camera about 2 weeks ago and then immediately installed the latest firmware. Since then I have the problem that the entered `}<strong parentName="p">{`http Request`}</strong>{` is encoded. But now the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm Server of the 9008`}</a>{` is not triggered anymore and cannot be used with my Smarthome System (Homematic CCU, FHEM, Node-RED, OpenHAB 2, Home Assistant). With the 5907 it still works.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "597px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8e31fa07ee99edeae2f9eef517b68891/17602/Alarmserver_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB90lEQVQoz4VSy27TQBT15wFLQAgJiXV/gm4KQumGikqwQnxCSyOxYJVSBGrsRKFRIHFYoDwcKbbH9mSennvHaJyHioBydDQ6c0fnPmbG23s7fvRqeP/ltwfH3+8eDe4c9v/k7a241bi6dzR4/Hq49yZ8eDzwDpuTRnP67N3k+dnk6dl0/2S6f3ptXYtt5MnJ9KA5a7yPXnxYHJxOPAO4zGVcyKRQSUJJFKdbkighUeL0PCaLhLhInETxcraMZ0s0xhMahrMinBejxSoM5+NWMGoF44tueN4JW0Hotv6o5f/42AnPna4jwfRz1yrlIaLgTAoupdBaobXV/yCh+hqpEq0HACTLiEOWJAkA2BqIqKQSQkghwcCOaEDI8ueSA1pXuawBAMaYXXprLReCcS6lNACAuCNatIjWWs8YQ2sg4tqzMSPSNJWUGiE0YzuWnGvGFGPO7NpTSkq5s1W1QClZt5e1/dwPiqBT+J2sHZC2Tzvd4tLn/UFVVZ611hiDiFprKaXWGgCuZbEbsZZ1XlOP6cwAUBQFpZQxtlqt8jznnGut11P8FYIzxtmmclmWegullNaac36D2Rgw2mwqU0o554wxIcRN71v3DDnNLj594ZcChDOnaZrXIITYGz5JfWSFIL3eFe1r1K5trfX6nX+783/DGIDSXeovY8k3QOrtyL0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e31fa07ee99edeae2f9eef517b68891/e4706/Alarmserver_01.avif 230w", "/en/static/8e31fa07ee99edeae2f9eef517b68891/d1af7/Alarmserver_01.avif 460w", "/en/static/8e31fa07ee99edeae2f9eef517b68891/52da7/Alarmserver_01.avif 597w"],
              "sizes": "(max-width: 597px) 100vw, 597px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8e31fa07ee99edeae2f9eef517b68891/a0b58/Alarmserver_01.webp 230w", "/en/static/8e31fa07ee99edeae2f9eef517b68891/bc10c/Alarmserver_01.webp 460w", "/en/static/8e31fa07ee99edeae2f9eef517b68891/9e35d/Alarmserver_01.webp 597w"],
              "sizes": "(max-width: 597px) 100vw, 597px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e31fa07ee99edeae2f9eef517b68891/81c8e/Alarmserver_01.png 230w", "/en/static/8e31fa07ee99edeae2f9eef517b68891/08a84/Alarmserver_01.png 460w", "/en/static/8e31fa07ee99edeae2f9eef517b68891/17602/Alarmserver_01.png 597w"],
              "sizes": "(max-width: 597px) 100vw, 597px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8e31fa07ee99edeae2f9eef517b68891/17602/Alarmserver_01.png",
              "alt": "INSTAR Full HD Alarmserver",
              "title": "INSTAR Full HD Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.31:8181/ccu3.exe?Antwort`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`dom.GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"motion_detected_8015"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`.State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`becomes ->`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.31:8181/ccu3.exe%3FAntwort%3Ddom.GetObject%28%22motion_detected_8015%22%29.State%281%29`}</code></pre></div>
    <p>{`Every `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`?`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`=`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`&`}</code>{` that I type in is encoded as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`%3F`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`%26`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`%3D`}</code>{`.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: To prevent the special characters from being encoded, you must use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`URL Queries`}</code>{` (click on the green plus symbol to add a new query):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "597px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9af449443c31c5de7283174cdf97414e/17602/Alarmserver_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACjElEQVQ4y22Ry27TQBSG81YlUhqnCQ0e24ln5ozHt5mxHefeW9oGkEBtRStBu2GRIiEQsEIseAJKKxbseAWqFja8B9hOqxb106+j0bGP/l/nlNY+/k7f/1JvL+N3l/7rCzo7h+NzeDUXK3ScN4/PyeynfHPR+3C5/ulPPPtRSl9+k0dn4eGpODxzn38l+yf04ITsf5nr2fxBD4r+if/iVB2dDmffvb3PJZsA5gJzYWc1JFxQVzhh5IQRD2MuYieMmK9I9ilT/rO0WYApLQH3WLrN4g23u+X3p15v2+9Pu5Pd3uZuurHTnez0NneT9adF3+9PnXQCyQTiNea6JcpcIsc47PvdDTHYlIPNZOVhsvIwHk/lYCsaTaPRNB5P43HW7Kw+4skqEUMadBh3S4wxpZSUKhRCRZFUChjDhBBKARgFoADAGMBcGX4E3cfZsOM4aZp2Op2iBkEAAJRSjLFhGK1Wy7Ks5i2Wm8jSIeKclwDA933P81zX5ZxjjGnmCa1Wq1arNRqNqqbdK5dvamFhoVopc+5ksaOcwvC6ttttXdeXlpYqlUr1NouL1XrjfubMGAtyAIDkYIwL53q9rut6tVqt3KZcLmua5hSxOedujncFYyxf2R0QQqSUQgibkGxhyRVRFEkpkyRRShX5/wMAbNsej0aj0dCwzMz5pmcQBJ7nhWHIGLtjmNI2tmWadlWKkDG/sxBCKeV5XrHtInN+U+bkZC8ADBAT9oQRsseQheZ3jnOiKCoMi3i6riOElnOazaaBkG6aDJk99EAfG8jUM2chRJgjpfQ8jxByfSqEUK1W0zStXq8bhmGZJjJNZNvUIrp+NVwgpQzD8Ho3N2MXyYExgvF4OByMhv+G/wKnFWzS1D1FOgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9af449443c31c5de7283174cdf97414e/e4706/Alarmserver_02.avif 230w", "/en/static/9af449443c31c5de7283174cdf97414e/d1af7/Alarmserver_02.avif 460w", "/en/static/9af449443c31c5de7283174cdf97414e/52da7/Alarmserver_02.avif 597w"],
              "sizes": "(max-width: 597px) 100vw, 597px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9af449443c31c5de7283174cdf97414e/a0b58/Alarmserver_02.webp 230w", "/en/static/9af449443c31c5de7283174cdf97414e/bc10c/Alarmserver_02.webp 460w", "/en/static/9af449443c31c5de7283174cdf97414e/9e35d/Alarmserver_02.webp 597w"],
              "sizes": "(max-width: 597px) 100vw, 597px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9af449443c31c5de7283174cdf97414e/81c8e/Alarmserver_02.png 230w", "/en/static/9af449443c31c5de7283174cdf97414e/08a84/Alarmserver_02.png 460w", "/en/static/9af449443c31c5de7283174cdf97414e/17602/Alarmserver_02.png 597w"],
              "sizes": "(max-width: 597px) 100vw, 597px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9af449443c31c5de7283174cdf97414e/17602/Alarmserver_02.png",
              "alt": "INSTAR Full HD Alarmserver",
              "title": "INSTAR Full HD Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      